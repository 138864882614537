<template>
  <div class="product-stock-status">
    <template v-if="!isSimple">
      <Button
        v-if="!isProductStockAlertStatus"
        variant="secondary"
        outline
        iconLeft
        size="xl"
        block
        :loading="pending"
        @click.prevent="handleAvailability"
      >
        <template #default>
          {{ $t('product.trade.btnNotifyStock') }}
        </template>
      </Button>

      <div
        v-else
        class="product-stock-status__text"
      >
        <Icon name="check" />
        {{ $t('product.trade.msgNotifyStock') }}
      </div>
    </template>

    <template v-else>
      <Button
        variant="secondary"
        outline
        square
        size="lg"
        :aria-label="arialAbel"
        :loading="pending"
        @click.prevent="handleAvailability"
      >
        <template #icon>
          <Icon :name="!isProductStockAlertStatus ? 'ring' : 'ring-off'" />
        </template>
      </Button>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { Icon } from '@shared/Icon';
import { Button } from '@shared/Button';

interface Props {
  id: number;
  isSimple?: boolean;
}

const props = defineProps<Props>();

const { t: $t } = useI18n();

const { openAuthModal } = useAuth();

const { user } = storeToRefs(useUserStore());

const { isAuth } = storeToRefs(useAuthStore());

const pending = ref<boolean>(false);

const arialAbel = computed((): string => {
  return !isProductStockAlertStatus.value ? $t('ariaLabel.reportAvailability') : $t('ariaLabel.noReportAvailability');
});
const isProductStockAlertStatus = computed(() => {
  return user.value?.productStockAlerts.some((product: any) => product.productId === props.id);
});

/**
 * Handles the availability status of the product and manages stock alerts.
 * @returns {Promise<void>}
 */
const handleAvailability = async (): Promise<void> => {
  await nextTick();

  if (!isAuth.value) {
    return openAuthModal(addStockAlert);
  }

  if (!isProductStockAlertStatus.value) {
    await addStockAlert();
  } else {
    await removeStockAlert();
  }
};

const { fetchStockAlertProductAdd, fetchStockAlertProductRemove } = useStockAlert();

/**
 * Adds a product to the stock alert list.
 * @returns {Promise<void>}
 */
const addStockAlert = async (): Promise<void> => {
  pending.value = true;

  try {
    await fetchStockAlertProductAdd(props.id);
  } finally {
    pending.value = false;
  }
};

const isRemoveFromCabinet = inject('isRemoveFromCabinet', false);

/**
 * Removes a product from the stock alert list.
 * @returns {Promise<void>}
 */
const removeStockAlert = async (): Promise<void> => {
  pending.value = true;

  try {
    await fetchStockAlertProductRemove(props.id, isRemoveFromCabinet);
  } finally {
    pending.value = false;
  }
};
</script>

<style lang="scss" src="./ProductStockStatus.scss" />
