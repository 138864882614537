<template>
  <div
    ref="productRef"
    class="product-tile"
  >
    <div class="product-tile__label">
      <template
        v-for="(label, idx) in labels"
        :key="idx"
      >
        <Badge
          v-if="label.isShow"
          :variant="label.variant"
          size="md"
        >
          {{ label.value }}
        </Badge>
      </template>
    </div>

    <slot
      v-if="isFavorite"
      name="favorite"
    >
      <FavoriteButton
        :class="['product-tile__favorite', { 'is-active': isRemoveFavorite }]"
        :data
        :isRemove="isRemoveFavorite"
        variant="round"
      />
    </slot>

    <LocaleLink
      :to="productLink"
      class="product-tile__picture"
      @click="onProductLink"
    >
      <picture v-if="data?.image?.xxs">
        <source
          :srcset="`${data.image.xxs} 1x, ${data.image.xxs2} 2x`"
          media="(max-width: 767px)"
        />

        <source
          :srcset="`${data.image.xs} 1x, ${data?.image.xs2} 2x`"
          media="(min-width: 768px)"
        />

        <img
          :src="data.image.xs"
          :alt="data.name"
          :title="data.name"
          loading="lazy"
          width="176"
          height="269"
        />
      </picture>
    </LocaleLink>

    <div class="product-tile__main">
      <LocaleLink
        class="product-tile__title"
        :to="productLink"
        @click="onProductLink"
      >
        {{ data?.name }}
      </LocaleLink>

      <ProductAuthors
        v-if="authors?.length"
        class="product-tile__authors"
        :data="authors"
        isLink
      />

      <Rating
        :current="data?.ratingValue"
        :amount="data?.reviewCount"
      />
    </div>

    <div class="product-tile__footer">
      <Price
        :val="valuePrice"
        :valOld="valuePriceOld"
        :hasInStock="hasInStock"
        class="product-tile__price"
      />

      <ProductStock
        :hasInStock="hasInStock"
        class="product-tile__stock"
      />

      <BuyButton
        v-if="hasInStock"
        :data
        square
        size="lg"
        class="product-tile__buy"
      />

      <ProductStockStatus
        v-else
        :id="data.id"
        class="product-tile__stock-status"
        isSimple
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { LocaleLink } from '@shared/LocaleLink';
import { Price } from '@shared/Price';
import { Rating } from '@entities/Rating';
import { BuyButton } from '@features/BuyButton';
import { FavoriteButton } from '@features/FavoriteButton';
import { ProductStock } from '@entities/ProductStock';
import { Badge } from '@shared/Badge';
import { ProductAuthors } from '@entities/ProductAuthors';
import { ProductStockStatus } from '@features/ProductStockStatus';
import type { IProduct } from '@types';

interface IProps {
  data: IProduct;
  isFavorite?: boolean;
  isRemoveFavorite?: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  isFavorite: true,
});

const $emit = defineEmits(['viewed', 'selected']);

const { authors, brand, productLink, hasInStock, valuePrice, valuePriceOld, labels } = useProductProperties(props.data);

const productRef = ref(null);
const isViewed = ref(false);
const isVisible = useElementVisibility(productRef);

const onProductLink = () => {
  $emit('selected', {
    item_id: props.data?.sku,
    item_name: props.data?.name,
    item_brand: brand.value,
    price: valuePrice.value,
  });
};

watchEffect(() => {
  if (!isViewed.value && isVisible.value) {
    isViewed.value = true;

    $emit('viewed', {
      item_id: props.data?.sku,
      item_name: props.data?.name,
      item_brand: brand.value,
      price: valuePrice.value,
    });
  }
});
</script>

<style lang="scss" src="./ProductTile.scss" />
