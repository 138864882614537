import type { IProduct } from '@types';

export const useStockAlert = () => {
  const { user } = storeToRefs(useUserStore());
  const { stockList } = storeToRefs(useStockListStore());

  const fetchStockList = async (productIds: { productId: number }[]): Promise<any[]> => {
    try {
      const list = await fetchApiGet('/stock-list', {
        data: {
          productIds,
        },
      });

      stockList.value = list;
      return list;
    } catch (error: any) {
      throw createError(error);
    }
  };

  const fetchStockAlertProductAdd = async (productId: number): Promise<void> => {
    try {
      await fetchApiPost('/stock-status', {
        data: {
          productId,
        },
      });

      user.value.productStockAlerts.push({ productId });
    } catch (error: any) {
      throw createError(error);
    }
  };

  const fetchStockAlertProductRemove = async (productId: number, isRemoveFromCabinet: boolean): Promise<void> => {
    try {
      await fetchApiDelete('/stock-status', {
        data: {
          productId,
        },
      });

      user.value.productStockAlerts = user.value.productStockAlerts.filter(
        (product: { productId: number }) => product.productId !== productId
      );

      if (isRemoveFromCabinet) {
        stockList.value = stockList.value.filter((product: IProduct) => product.id !== productId);
      }
    } catch (error: any) {
      throw createError(error);
    }
  };

  return {
    fetchStockList,
    fetchStockAlertProductAdd,
    fetchStockAlertProductRemove,
  };
};
