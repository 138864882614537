<template>
  <div class="buy-btn">
    <Button
      variant="primary"
      :square="square"
      :outline="!!isProductInCart"
      :iconLeft="iconLeft"
      :size="size"
      :block="block"
      :aria-label="arialAbel"
      :loading="pending"
      @click="handleBuyOrCheck"
    >
      <template
        v-if="title"
        #default
      >
        {{ isProductInCart ? secondTitle || title : title }}
      </template>

      <template
        v-if="!noIcon"
        #icon
      >
        <Icon :name="`${!isProductInCart ? 'cart' : 'check'}`" />
      </template>
    </Button>
  </div>
</template>

<script lang="ts" setup>
const { t: $t } = useI18n()

import type { IProduct } from '@types';
import { Button } from '@shared/Button';
import { Icon } from '@shared/Icon';

interface Props {
  data: IProduct;
  size?: 'lg' | 'xl';
  title?: string;
  secondTitle?: string;
  iconLeft?: boolean;
  square?: boolean;
  block?: boolean;
  noIcon?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  size: undefined,
  title: '',
  secondTitle: '',
});

const { trackViewedCart } = useAnalytics();

const { openModalCart, addProduct } = useCart();

const { cart } = storeToRefs(useCartStore());

const pending = ref(false);

/**
 * Computed property to check if the current product is in the cart.
 * @computed
 * @returns {boolean} - Returns `true` if the product is in the cart and in stock, `false` otherwise.
 */
const isProductInCart = computed(() => {
  return cart.value?.items || !props.data.id
    ? !!cart.value.items.some((item: any) => item?.product?.id === props.data.id)
    : false;
});

/**
 * Computed property to get the appropriate ARIA label based on whether the product is in the cart.
 * @returns {string} - Returns the ARIA label for the product's button.
 */
const arialAbel = computed(() => {
  return isProductInCart.value ? $t('ariaLabel.buy') : $t('ariaLabel.goToCart');
});

/**
 * Adds the current product to the cart.
 * @async
 * @function addToCart
 * @returns {Promise<void>} - A promise that resolves when the product has been added to the cart.
 */
const addToCart = async (): Promise<void> => {
  pending.value = true;

  try {
    await addProduct({
      sku: props.data?.sku,
      price: props.data?.priceRange?.minimumPrice?.finalPrice?.value,
      item: { product: props.data },
    });

    openModalCart();
  } finally {
    pending.value = false;
  }
};

const openCart = () => {
  openModalCart();
  trackViewedCart();
};

/**
 * Handles the action of buying a product or checking the cart.
 * If the product is not in the cart, it adds it to the cart.
 * Otherwise, it opens the cart modal.
 * @function handleBuyOrCheck
 * @returns {void}
 */
const handleBuyOrCheck = (): void => {
  !isProductInCart.value ? addToCart() : openCart();
};
</script>

<style lang="scss" src="./BuyButton.scss" />
